import { ConnectButton } from "@rainbow-me/rainbowkit";
import "./Connect.scss";

export const Connect = () => {
  return (
    <div
    className="walletWrapper"
    style={{
        position: "relative",
        top: "25px",
        width: "100%",
        margin: "0 auto",
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "flex-end",
    }}>
        <ConnectButton showBalance={false} accountStatus="address" />
    </div>
  );
};
