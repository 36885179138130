import { motion } from "framer-motion";
import {useEffect, useState} from "react";
import "./Body.scss"
import Typed from "react-typed";

import { GoogleSpreadsheet } from "google-spreadsheet";

import { useAccount, useConnect } from 'wagmi';
import { InjectedConnector } from 'wagmi/connectors/injected';

const sheetID = 0;
const spreadsheetId = "1ELmejxsUF0gPGKxRRXwivcyPdNSfZTKD7QgALWCgsmI";
const doc = new GoogleSpreadsheet([spreadsheetId]);
// const doc = new GoogleSpreadsheet([process.env.SPREADHSEET_ID]);
// Add typed.js for state animations

function App() {
    const [userRow, setRow] = useState();
    const [isRegistered, setIsRegistered] = useState();
    const { data } = useAccount();
    const { activeConnector } = useConnect({
      connector: new InjectedConnector(),
      async onConnect(data) {
      }
    });

    useEffect(() => {
        const getDoc = async() => {
            await doc.useServiceAccountAuth({
                client_email: "coldie-spreadsheet@esoteric-cider-175601.iam.gserviceaccount.com",
                private_key: "-----BEGIN PRIVATE KEY-----\nMIIEugIBADANBgkqhkiG9w0BAQEFAASCBKQwggSgAgEAAoIBAQDWUrPI4UL+U2w9\ndoMow8jz/qnEi+HiNUzIMAcBOYqNKrI+lzSVQeKbAuD2hH5vCfL1IE7O6RbOlD0Q\npnE1xZk2+98gqWOyILzzsMKHS3BJTqKlmSGIt9csWSqPStbnMTByyiwApCEKHrVF\nLs+zpHp1wtvxgGIy0ebbxGe+8ZJBSanWVE38XN6Tkm8d6oI4q0HuZa3x1fxW3L2A\nHnjKxxSepiChr9qhYmQnPt+3NPuimH0vSGtlKDHFJvnf7WMAjo2VdRX8sI9a2neT\n0N3FLsFHprCvxCDXQY2Gar3af3Cjb5br6uxJiX40oDxHKlxMSSt7ovMs9Wo7jhHJ\nEyxLhMCDAgMBAAECggEAQ35G5M9fmBTwZMiyFD/XxFLh5qYAjAqp8+7//bg/tCYK\nfOCELC7T7GnzK17dLB2vd9dlSg6oYoTXWCt6QmtITULzaOc/IKTjrEBxekbdBRDw\n8oJvR41Rg0NPPlHcrHDWp4eYZNlLGFNEVZWgzhtRf01Ok1aRVhy4/WN0InMT0mAL\nRg1oUQno46FTVs+IY5jQVRDsafwlzUhI36eAI6PFR4rz+d4fZ6WM2QiZut5/yW5s\nyiNwEpCwUWdUjNhncQcaV0ImW1alp3zQ3dStIKDcIXrcIEf9umnJZAT5tgOnqC45\n2FNSN0uI/6akV18oOcSIekjkqo6NZ75iTvPYaU76jQKBgQDrnw6IMbVbJuQ+V2+A\np9a5ZCPIgsHeCM5hecd/cqo80vRF9exhAmbozidKIuGh9+XqPz2MqD1S7AY0SvqP\nLh47GIqKOawfiBzhv2Qke9EnStldVKmfKk/hN433EVYTUWZyt0MkEdRIQ9hlK2qM\nug4z0eyFdsG7n8USsDDAYVVG9QKBgQDo3BN53Ha0W5qJaaHFuJUf/pKgv3SsfJUa\nKp3nE9rEkMqcwjOBMLlGiXS6l+OOqFT7qFz1ByVehXrTT5X/nxRV9ONIL1doQuRI\nB7I0j1r5j/UBM9GQj8HaFMtzktvIFW/5+1jK+3VgRenrnK7EOosRxo9J0K6E9Vto\nDkIun+yOlwKBgBDQoBPeIEgqBV3hlYaD89H1MIyNsFItJLVWIIOa5kVC9MXNYBoo\nNrnkhG9I//s0GsdVzgmss/un9mVi+HChZOzounJU3VC+wPjnVLk50NpSACMl+Jjw\nRxUWht4b8Z2dUIddRsBbOWAS322Q5KC4Ym0WTR/VMsw2AbVst33zNEfFAoGAZEhj\nGtgXRxUsOIGFaeFUV7MK8q+BT8dXby1rU1j5ni29FEWOaNTS9c28t58yIP3dm4Om\nhN/O+CYD/30G3E9mMp6EQ1IgEoa8SUkHNhuG0nNs0FfAqMFAeur5s4xLmp+g2cel\nNcWVhyCU3SNdIKUVv1ID44Y7oocIFogNeIbnGYECfwihds5Ja9veZPBkNbTAJMvt\nkE8bydTEoebQ2OeoyiS/JzIq1l88Mfw+iRs4/zgpzFR4Ipw2rW3qL6H0l6s403nf\n2mB/NK2GTiEhWiCa6iUWsGdSxEp0ETLeEqT78HBiSKrDwCG7z+KVGQW6sJK/hf3Q\nQ7nPvDRkL/Z/2ONSo5g=\n-----END PRIVATE KEY-----\n"
            });
            
            await doc.loadInfo();

            const sheet = doc.sheetsByIndex[sheetID];
            const rows = await sheet.getRows();
            let hasMatch = false;
            for (let i = 0; i < rows.length; i++) {
                const row = rows[i];
                if (data.address.toLowerCase() === row["Wallet Address"].toLowerCase()) {
                    hasMatch = true;
                    setIsRegistered(true);
                    setRow(row);
                    break;
                }
            }
            if (!hasMatch) setIsRegistered(false);
        }

        getDoc();
    }, [data]);
    return (
        <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
        >
            { !activeConnector &&
                <motion.div
                    initial={{opacity: 0}}
                    animate={{opacity: 1}}
                    style={{marginTop: "36px"}}>
                    <p style={{fontSize: "2em"}}><Typed strings={["Please Connect Your Wallet to Begin"]} typeSpeed={50} showCursor={true}></Typed></p>
                </motion.div>
            }
            {activeConnector && isRegistered === undefined &&
             <motion.div
                initial={{opacity: 0}}
                animate={{opacity: 1, transition: { duration: .9, ease: "linear"}}}
                style={{marginTop: "36px"}}>
                 <p style={{fontSize: "2em"}}>Retrieving Data</p>
             </motion.div>
            }
            {activeConnector && isRegistered &&
             <motion.div
             initial={{opacity: 0}}
             animate={{opacity: 1}}
                >
                 <motion.img
                    initial={{opacity: 0}}
                    animate={{opacity: 1, transition: {duration: "0.9"}}}
                    style={{width: "55%", minWidth: "350px", margin: "0 auto 4em auto"}} src={userRow["Portrait URL"]} alt="DEyes Assembled" />
             </motion.div>
            }
            {activeConnector && isRegistered === false &&
                <motion.div
                    initial={{opacity: 0}}
                    animate={{opacity: 1, transition: {duration: "0.9"}}}
                    style={{marginTop: "36px"}}
                >
                    <p style={{fontSize: "2em"}}><Typed strings={["...","...","You are not registered for a DEyes Assembled"]} typeSpeed={40} backSpeed={90} showCursor={false} startDelay={400}></Typed></p>
                </motion.div>
            }
        </motion.div>
    )
}

export default App
