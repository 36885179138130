import "@rainbow-me/rainbowkit/styles.css";
import "./App.css"
import { motion } from "framer-motion";
import Body from "./Body";

import {
  getDefaultWallets,
  RainbowKitProvider,
  darkTheme
} from "@rainbow-me/rainbowkit";
import { publicProvider } from 'wagmi/providers/public';

import { chain, configureChains, createClient, WagmiConfig } from "wagmi";
import { alchemyProvider } from 'wagmi/providers/alchemy';
import {Connect} from "./Connect";

const { chains, provider } = configureChains(
  [chain.mainnet],
  [alchemyProvider([process.env.ALCHEMY_ID]), publicProvider()]
);

const { connectors } = getDefaultWallets({
  appName: "DEyes: The Assembled Collection",
  chains
});

const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider
});

function App() {

    return (
        <WagmiConfig client={wagmiClient}>
            <RainbowKitProvider chains={chains} theme={darkTheme()}>
                <div className="App">
                  <div style={{display: "flex", minHeight: "15vh"}}>
                    <img style={{width: "135px", height: "99px", marginTop: "10px"}} src="/coldie-logo.gif" alt="" />
                    <Connect />
                  </div>
                    <header className="App-header">
                      <motion.h1
                      initial={{opacity: 0}}
                      animate={{opacity: 1, transition: { duration: .5, ease: "linear"}}}
                      style={{marginBottom: "0px", marginTop: "1.8rem", position: "relative", top: "-40px", fontSize: "3em"}}>
                        DEyes: The Assembled Collection
                        </motion.h1>
                    </header>
                    <Body />
                </div>
            </RainbowKitProvider>
        </WagmiConfig>
    )
}

export default App
